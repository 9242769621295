import { TimelineMax, TweenMax } from 'gsap';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

var homeLogoTrigger = '.banner-home+*';
var homeLogoTarget = '.banner-home__logo';
var homeMenuTarget = '.header-home';

if($(homeLogoTarget).length) {

    if(!homeLogoController) {
        // initialise a scrollmagic controller
        var homeLogoController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onLeave"}});

        // build scenes
        var scene = new ScrollMagic.Scene({triggerElement: homeLogoTrigger, offset: -102})
          .setClassToggle(homeLogoTarget, 'is-hidden')
          .addTo(homeLogoController);

        var scene2 = new ScrollMagic.Scene({triggerElement: homeLogoTrigger, offset: -102})
          .setClassToggle(homeMenuTarget, 'is-scrolled')
          .addTo(homeLogoController);
    }
}

var homeArrow = $('.banner-home__arrow');

if(homeArrow.length) {
  function homeArrowReset() {
    homeArrow.removeClass('is-active');
  }

  function homeArrowBounce() {
    homeArrow.addClass('is-active');
    var bounceRemove = setTimeout(homeArrowReset, '2000');
    var bounceRunning = setTimeout(homeArrowBounce, '6000');
  }

  var bounceInit = setTimeout(homeArrowBounce, '6000');

  homeArrow.on('click', function(e) {
    e.preventDefault();
    var target = $('.main');

    if (target.length) {
        $('html, body').stop().animate({
            scrollTop: target.offset().top - 70
        }, 800, 'swing');
    }
  });
}

// bg behaviour
var bannerHome = $('.banner-home');

/*if(bannerHome.length) {
    var lFollowX = 0,
    lFollowY = 0,
    x = 0,
    y = 0,
    friction = 1 / 30;
    var bgTarget = $('.banner-home__bg');

    function moveBackground() {
        x += (lFollowX - x) * friction;
        y += (lFollowY - y) * friction;

        var translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

        bgTarget.css({
          '-webit-transform': translate,
          '-moz-transform': translate,
          'transform': translate
        });

        window.requestAnimationFrame(moveBackground);
    }

    $(window).on('mousemove click', function(e) {

        var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
        var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
        lFollowX = (20 * lMouseX) / 100;
        lFollowY = (10 * lMouseY) / 100;

    });

    moveBackground();
}*/
