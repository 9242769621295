var map3d = '';
var map2d = '';

var mapPrefix = '#mapplic-id';

var map2dId = $('.site-plan__plan--2d').data('plan');
var map3dId = $('.site-plan__plan--3d').data('plan');

if(map2dId) {
    map2d = $(mapPrefix + map2dId);
}

if(map3dId) {
    map3d = $(mapPrefix + map3dId);
}

var plan_retailers = $('.site-plan__retailers');
var planItems = plan_retailers.find('.retailer-card');


// Detect when an element enters the viewport
// $.fn.isInViewport = function() {
//     var offset = 0;
//     var elementTop = $(this).offset().top + offset;
//     var elementBottom = elementTop + $(this).outerHeight();
//     var viewportTop = $(window).scrollTop();
//     var viewportBottom = viewportTop + $(window).height();
//     return elementBottom > viewportTop && elementTop < viewportBottom;
// };

// Scroll to named element
$.fn.scrollView = function () {
    return this.each(function () {
      $('html, body').animate({
        scrollTop: $(this).offset().top
      }, 1000);
    });
}



function scrollSitePlan() {

    /* position of an element relative to the top of the viewport */
    var siteplanpos = $('.site-plan').offset().top - $(window).scrollTop();
    var offset = -25;

    //console.log(siteplanpos);

    if (siteplanpos < offset) {
        console.log("Scroll");
        $('.site-plan').scrollView();
    }
}

if(map3d.length || map2d.length) {

    planItems.on('click', '.js-modal-close', function(e){
        e.preventDefault();
        setTimeout(function() {
            $(this).closest('.retailer-card').removeClass('is-active');
        }, 400);

        $(this).closest('.site-plan__retailers-box').removeClass('is-active');
        $(this).closest('.site-plan__retailers-box').removeClass('site-plan__retailers-box--vacant');
        var self2d = map2d.data('mapplic');
        if(self2d) {
            self2d.hideLocation();
        }
        var self3d = map3d.data('mapplic');
        if(self3d) {
            self3d.hideLocation();
        }

        // rich @ woven
        $('.site-plan__plan').removeClass('retailer-card-is-active');
        $('.header').removeClass('retailer-card-is-active');
    });

    // Location opened
    map3d.on('locationopened', function(e, location) {
        // location grants full access to the location
        var loc = location.id;
        if (loc.indexOf("vacant") >= 0) {
            // vacant lot handling
            $('.site-plan__retailers .retailer-card').removeClass('is-active');
            $('.site-plan__retailers .site-plan__retailers-box').addClass('is-active');
            $('.site-plan__retailers .site-plan__retailers-box').addClass('site-plan__retailers-box--vacant');
            $('.site-plan__retailers .retailer-card--vacant').addClass('is-active');

            // rich @ woven
            $('.site-plan__plan').addClass('retailer-card-is-active');
            $('.header').addClass('retailer-card-is-active');

            scrollSitePlan();

        } else {
            $('.site-plan__retailers .retailer-card').removeClass('is-active');
            $('.site-plan__retailers .site-plan__retailers-box').removeClass('site-plan__retailers-box--vacant');
            $('.site-plan__retailers .site-plan__retailers-box').addClass('is-active');
            $('.site-plan__retailers .retailer-card--' + loc).addClass('is-active');

            // rich @ woven
            $('.site-plan__plan').addClass('retailer-card-is-active');
            $('.header').addClass('retailer-card-is-active');

            scrollSitePlan();

        }
    });

    // Location opened
    map2d.on('locationopened', function(e, location) {
        // location grants full access to the location
        // console.log(location.id + ' opened.');
        var loc = location.id;
        if (loc.indexOf("vacant") >= 0) {
            // vacant lot handling
            $('.site-plan__retailers .retailer-card').removeClass('is-active');
            $('.site-plan__retailers .site-plan__retailers-box').addClass('is-active');
            $('.site-plan__retailers .site-plan__retailers-box').addClass('site-plan__retailers-box--vacant');
            $('.site-plan__retailers .retailer-card--vacant').addClass('is-active');

            // rich @ woven
            $('.site-plan__plan').addClass('retailer-card-is-active');
            $('.header').addClass('retailer-card-is-active');

            scrollSitePlan();

        } else {
            $('.site-plan__retailers .retailer-card').removeClass('is-active');
            $('.site-plan__retailers .site-plan__retailers-box').removeClass('site-plan__retailers-box--vacant');
            $('.site-plan__retailers .site-plan__retailers-box').addClass('is-active');
            $('.site-plan__retailers .retailer-card--' + loc).addClass('is-active');

            // rich @ woven
            $('.site-plan__plan').addClass('retailer-card-is-active');
            $('.header').addClass('retailer-card-is-active');

            scrollSitePlan();

        }
    });

    $('.site-plan__switch--2d').on('click touchend', function(e) {
        e.preventDefault();

        console.log(map2d);

        if(!$(this).hasClass('is-active')) {
            $('.site-plan__switch--3d').removeClass('is-active');
            $('.site-plan__plan--3d').removeClass('is-active');
            $('.site-plan__plan--2d').addClass('is-active');
            $(this).addClass('is-active');
            $(window).resize();
        }
    });

    $('.site-plan__switch--3d').on('click touchend', function(e) {
        e.preventDefault();

        console.log(map3d);

        if(!$(this).hasClass('is-active')) {
            $('.site-plan__switch--2d').removeClass('is-active');
            $('.site-plan__plan--2d').removeClass('is-active');
            $('.site-plan__plan--3d').addClass('is-active');
            $(this).addClass('is-active');
            $(window).resize();
        }
    });

    $(window).on('scroll', function(){

        if (!$('.site-plan').isInViewport()) {
            $('.header').removeClass('retailer-card-is-active');
        } else if ( $('.site-plan__plan').hasClass('retailer-card-is-active') && $('.site-plan').isInViewport()) {
            $('.header').addClass('retailer-card-is-active');
        }

    });
}
