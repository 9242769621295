/* eslint prefer-arrow-callback:0 */
/* eslint global-require:0 */

/*
* JS external modules/libraries
*/

// import jquery. sort some namespaces out, as this will be largely handled by webpack.
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

// import modernizr build
require('./js/vendor/modernizr.js');

// slick carousel
const slick = require('slick-carousel');


$.fn.isInViewport = function () {
  const elementTop = $(this).offset().top;
  const elementBottom = elementTop + $(this).outerHeight();

  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + ($(window).height() - 100);

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

function checkVisibility() {
  $('.js-anim').each(function () {
    if ($(this).isInViewport() === true && !$(this).hasClass('.js-anim--active')) {
      if($(this).hasClass('js-anim--children')) {
        var animInterval = $(this).data('anim-interval') || 100;
        var animDelay = $(this).data('anim-delay') || 0;
        var that = this;
        var delay = setTimeout(function() {
          $(that).children().each(function(i) {
            var interval = animInterval * i;
            var target = this;
            var t = setTimeout(function() {
              $(target).addClass('js-anim--active');
            }, interval);
          });
        }, animDelay);
      }
      else {
        var animDelay = $(this).data('anim-delay') || 0;
        var that = this;
        var delay = setTimeout(function() {
          $(that).addClass('js-anim--active');
        }, animDelay);
      }
    }
  });
}

$(document).ready(function () {

  $(window).scroll(function () {
    checkVisibility();
  });

  checkVisibility();

  require('./js/partials/maps.js');
  require('./js/partials/responsive-svg.js');
  require('../components/header/index.js');
  require('../components/header-home/index.js');
  require('../components/gallery/index.js');
  require('../components/banner/index.js');
  require('../components/banner-home/index.js');
  require('../components/retailer-slider/index.js');
  require('../components/site-plan/index.js');
  require('../components/location/index.js');

  // prep js-anim--children
  $('.js-anim--children').each(function () {
    const animType = $(this).data('anim-type') || 'fadein';
    $(this).children().each(function(i) {
      $(this).addClass('js-anim--' + animType);
    });
  });

});

$(window).on('load', function () {
  checkVisibility();
});
