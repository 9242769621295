/*
* Menu handling
*/

// main menu toggle

$('.js-toggle-nav.header__toggle--nav').click(function() {
    $(this).toggleClass('is-active');
    $('.header__menu').toggleClass('is-active');
    $('.header__image').toggleClass('js-anim--active');
    $('body').toggleClass('mobile-menu--active');
});
