// gallery slider

var gallery = $('.gallery');

if(gallery.length) {

    gallery.each(function(index) {

        var slider = $(this).find('.gallery__slider');

        $(this).find('.gallery__slider-control--left').on('click', function(e) {
            slider.slick('slickPrev');
        });

        $(this).find('.gallery__slider-control--right').on('click', function(e) {
            slider.slick('slickNext');
        });

        slider.slick({
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 8000,
            infinite: true,
            speed: 800,
            slidesToScroll: 1,
            adaptiveHeight: false,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        centerMode: false,
                        variableWidth: false,
                        adaptiveHeight: true
                    }
                }
            ]
        });

        var modal = $(this).find('.gallery__modal');
        var modalImage = $(this).find('.gallery__modal-image');
        var triggers = $(this).find('.gallery__slider-trigger');
        var modalClose = $(this).find('.gallery__modal-close');

        triggers.each(function(index) {
            $(this).on('click', function(e) {
                if(window.innerWidth > 600) {
                    var imgSrc = $(this).data('gallery-src');

                    if(imgSrc.length) {
                        e.preventDefault();
                        modal.addClass('is-active');
                        modalImage.html('<img src="' + imgSrc + '">');
                        slider.slick('slickPause');
                    }
                }

            });
        });

        modalClose.on('click', function(e) {
            e.preventDefault();
            modal.removeClass('is-active');
            slider.slick('slickPlay');
        });

        $(document).keyup(function(e) {
          if (e.keyCode === 27) {
            // capture ESC key
            modal.removeClass('is-active');
            slider.slick('slickPlay');
          }
        });
    });
}
