// retailer slider

var retailers = $('.retailer-slider');

if(retailers.length) {

    retailers.each(function(index) {

        var slider = $(this).find('.retailer-slider__slider');
        var sliderItems = $(this).find('.retailer-slider__slider-item');
        var modal = $(this).find('.retailer-slider__modal');
        var modalItems = $(this).find('.retailer-card');
        var retailer = $(this);

        slider.on('click', '.retailer-slider__slider-item', function(e) {
            if ($(window).width() < 900) {
                var current = $(this).attr('data-index');
                modalItems.removeClass('is-active');
                modal.addClass('is-active');
                modalItems.eq(current).addClass('is-active');
                retailer.addClass('modal-is-active');
            }
        });

        modalItems.on('click', '.js-modal-close', function(e){
            e.preventDefault();
            $(this).closest('.retailer-slider__modal').removeClass('is-active');
            retailer.removeClass('modal-is-active');
        });

        $(this).find('.retailer-slider__slider-control--left').on('click', function(e) {
            slider.slick('slickPrev');
        });

        $(this).find('.retailer-slider__slider-control--right').on('click', function(e) {
            slider.slick('slickNext');
        });

        slick_settings = {
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 8000,
            infinite: true,
            speed: 800,
            slidesToScroll: 1,
            adaptiveHeight: false,
            slidesToShow: 5,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        }

        slider.slick(slick_settings);

        $(window).on('resize', function() {
            if ($(window).width() < 520) {
              if (slider.hasClass('slick-initialized')) {
                slider.slick('unslick');
              }
              return
            }

            if (!slider.hasClass('slick-initialized')) {
              return slider.slick(slick_settings);
            }
          });
    });
}


