/*
* Menu handling
*/

// home menu toggle

$('.js-toggle-nav.header-home__toggle--nav').click(function() {
    $(this).toggleClass('is-active');
    $('.header-home__menu').toggleClass('is-active');
    $('.header-home__image').toggleClass('js-anim--active');
    $('body').toggleClass('mobile-menu--active');
});
