var bannerArrow = $('.banner__arrow');

if(bannerArrow.length) {
  function bannerArrowReset() {
    bannerArrow.removeClass('is-active');
  }

  function bannerArrowBounce() {
    bannerArrow.addClass('is-active');
    var bounceRemove = setTimeout(bannerArrowReset, '2000');
    var bounceRunning = setTimeout(bannerArrowBounce, '6000');
  }

  var bounceInit = setTimeout(bannerArrowBounce, '6000');

  bannerArrow.on('click', function(e) {
    e.preventDefault();
    var target = $('.main');

    if (target.length) {
        $('html, body').stop().animate({
            scrollTop: target.offset().top - 70
        }, 800, 'swing');
    }
  });
}

// banner parallax

import { TimelineMax, TweenMax, Linear } from 'gsap';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic';

const parallaxTarget = '.banner';

if($(parallaxTarget).length) {

    // initialise a scrollmagic controller
    var bannerController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onLeave", duration: "100%"}});

    // build scenes
    var scene = new ScrollMagic.Scene({triggerElement: parallaxTarget})
          .setTween(".banner__bg", {y: "300px", ease: Linear.easeNone})
          //.addIndicators()
          .addTo(bannerController);

    var scene = new ScrollMagic.Scene({triggerElement: parallaxTarget})
          .setTween(".banner__bg-mobile", {y: "300px", ease: Linear.easeNone})
          //.addIndicators()
          .addTo(bannerController);


}
